import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tab, Tabs } from 'react-bootstrap';
import queryString from 'query-string';
import { toastr } from 'react-redux-toastr';

import Project from '../../components/project/Index';

import Background from '../../components/Background';
import WeekLists from '../../components/layout/WeekList';
import LeaveStatsView from '../../components/layout/LeaveStatsView';

import * as UserAction from '../../actions/user';
import * as ProjectAction from '../../actions/project';
import * as WeekAction from '../../actions/week';

import UserService from '../../services/UserApi';
import ProjectApi from '../../services/ProjectApi';
import WeekApi from '../../services/WeekApi';

import Team from '../teampage/Index';
import Stats from '../leave/Stats';

import WorkFromHomeIcon from '../../images/ic-work-home.svg';
import OfficialTripIcon from '../../images/ic-official-trip.svg';
import OfficialEventIcon from '../../images/podium.svg';
import AnnualLeaveIcon from '../../images/ic-annual-leave.svg';
import SickLeaveIcon from '../../images/ic-sick-leave.svg';
import MenstrualLeaveIcon from '../../images/menstrualleave.svg';
import Spinner from '../../components/layout/Loader';


class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			key: 1,
			isActive: true,
			menuOpen: false,
			deliverableOpen: false,
			year: moment().year()
		};
	}

	componentDidMount() {
		const { userId } = this.props.auth.user.id;

		const keyValue = queryString.parse(this.props.location.search);
		if (keyValue && keyValue.key) {
			this.setState({ tabKey: keyValue.key });
		} else {
			this.setState({ tabKey: '1' });
		}

		// this.setState({ userId });
		this.setState({ isSupervisor: this.props.auth.user.isSupervisor });
		if (userId) {
			this.setState({ userId })
			this.props.fetchUserProfile(userId)
		} else {
			this.props.fetchUserProfile();
		};

		this.props.getWeekData(this.state.year);
	}

	componentDidUpdate(nextProps, prevState) {
		if (
			this.state.selectedWeek !== prevState.selectedWeek ||
			this.props.match.params.userId !== nextProps.match.params.userId
		) {
			let weekId = this.state.selectedWeek ? this.state.selectedWeek.id : prevState.selectedWeek.id;
			this.listUserProjects(weekId);
		}

		if (
			this.state.userDetail &&
			this.state.userDetail !== prevState.userDetail
		) {
			this.setState({ userId: this.state.userDetail.id });
		}
	}

	componentWillUnmount() {
		this.props.resetUserProfile();
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const keyValue = queryString.parse(nextProps.location.search);
		let data = {};
		if (prevState.tabKey !== keyValue.key) {
			if (keyValue && keyValue.key) {
				data.tabKey = keyValue.key;
			}
		}
		if (nextProps.user !== prevState.user) {
			data.userDetail = nextProps.user.userDetail;
		}
		if (
			Object.keys(nextProps.weekData).length &&
			nextProps.weekData !== prevState.weekData
		) {
			data.weekList = nextProps.weekData.weeks;
			data.selectedWeek = nextProps.weekData.weeks.find(
				week => week.isCurrentWeek
			);
		}
		if (nextProps.match.params.userId &&
			parseInt(nextProps.match.params.userId) !== parseInt(prevState.userId)
		) {
			data.userId = nextProps.match.params.userId;
			nextProps.fetchUserProfile(nextProps.match.params.userId)
		}
		// if (
		// 	nextProps.match.params.userId &&
		// 	nextProps.projectList !== prevState.projectList &&
		// 	prevState.selectedWeek
		// ) {
		// 	nextProps.listUserProjects(prevState.selectedWeek.id, nextProps.match.params.userId)
		// }
		return data;
	}

	listUserProjects(weekId) {
		let userId = this.state.userId ? this.state.userId : this.props.auth.user.id;
		if (userId) {
			ProjectApi.listUserWeekProject({
				weekId: weekId,
				userId: userId
			})
				.then(response => {
					let hour = 0;
					response.data.projects.forEach(p => {
						hour += p.hour || 0;
					});
					this.setState({
						projectList: response.data.projects,
						hour: hour || 0
					});
				})
				.catch(err => {
					toastr.error('', err.message);
				});
		}
	}

	getWeekDetail = week => {
		if (week) {
			let weekId = week.id;
			this.props.getUserAttendance(weekId);
			this.props.listUserProjects(weekId);
		}
	}

	handleMenuClick = (projects, projectData = null) => {
		this.setState({
			menuOpen: !this.state.menuOpen,
			projects,
			projectData
		});
	};

	handleDeliverableClick = (projectsList, project = null, ) => {
		this.setState({
			deliverableOpen: !this.state.deliverableOpen,
			projectDetailForDeliverable: project,
			deliverableProjectsList: projectsList
		});
	};

	handleTabSelect = week => {
		this.setState({ selectedWeek: week, isActive: true });
		this.props.history.push(`/dashboard/${week.weekNo}/${week.year}`);
	};

	estimatedTab = i => {
		this.props.history.push(`/profile?key=${i}`);
	};

	render() {
		let {
			userDetail,
			hour,
			projectList,
			selectedWeek,
			tabKey,
			isSupervisor,
			userId
		} = this.state;
		let weekList = [];
		// if (projectList && projectList.length) {
		// 	weekList = projectList[0].projectDetail.weeks;
		// }
		if (!this.state.selectedWeek) return <p></p>;
		console.log(this.state.selectedWeek);

		return (
			<ProfileWrapper>
				<WeekLists
					selectedKey={this.state.key}
					handleTabSelect={this.handleTabSelect}
					selectedWeek={this.state.selectedWeek}
					isActive={this.state.isActive}
				/>
				<Background />

				{userDetail && (
					<InnerTemplate>
						<ProfileHeader>
							<div className="profile-image">
								<img
									src={
										userDetail.avatar
											? `${process.env.REACT_APP_ASSETS_URL}images/uploads/users/${userDetail.id}/${userDetail.avatar}`
											: userDetail.image
									}
									alt="profile"
								/>
							</div>
							<div className="profile-info">
								<div className="profile-attendance">
									<h3>{`${userDetail.firstName} ${userDetail.lastName} ${
										+userDetail.id === +this.props.auth.user.id ? '(YOU)' : ''
										}`}</h3>
									{userDetail.attendances.length ? (
										<strong className="present">
											Present
											<small>{`(In at ${moment(
												userDetail.attendances[0].inTime
											).format('HH:mm')} am today)`}</small>
										</strong>
									) : (
											// <strong className="absent">Absent</strong>
											<strong className="absent">
												{!userDetail.workingFromHome && !userDetail.officialTrip && !userDetail.leave ? 'Absent' : ''}
												{userDetail.workingFromHome ? (
													<>
														Working from home
														<img
															src={WorkFromHomeIcon}
															alt="workingfromhome"
															width="20"
															height="17"
															className="leave-icon"
														/>
													</>
												) : (
														''
													)}
												{userDetail.officialTrip ? (
													<>
														{`Out on an ${
															userDetail.officialTrip.type === 'officialTrip'
																? 'Official trip'
																: 'Official event'
															}`}
														<img
															src={
																userDetail.officialTrip.type === 'officialTrip'
																	? OfficialTripIcon
																	: OfficialEventIcon
															}
															alt="OfficialTrip"
															width="16"
															height="16"
															className="leave-icon"
														/>
													</>
												) : (
														''
													)}
												{userDetail.leave ? (
													<>
														{userDetail.leave}
														<img
															src={
																userDetail.leave === 'Annual Leave'
																	? AnnualLeaveIcon
																	: userDetail.leave === 'Menstrual Leave'
																		? MenstrualLeaveIcon
																		: SickLeaveIcon
															}
															alt="Leave"
															width="16"
															height="16"
															className="leave-icon"
														/>
													</>

												) : (
														''
													)}
											</strong>
										)}
								</div>
								<div className="other-info">
									<span>{userDetail.email}</span>
									<span>{userDetail.mobile}</span>
									<span>{userDetail.bloodGroup}</span>
									{this.props.auth.user.id === userDetail.id && (
										<Link to="/edit/profile">Edit your profile</Link>
									)}
								</div>
							</div>
						</ProfileHeader>
						<EstimatedHoursWrap>
							<div>
								<h4>{`${
									+this.props.auth.user.id === +userDetail.id
										? 'Your'
										: userDetail.firstName + "'s"
									} estimated hours for this week`}</h4>
								<EstimatedHour>
									{hour || hour === 0 ? <strong>{hour}</strong> : <Spinner />} hrs
								</EstimatedHour>
							</div>
							<ProgressHourWrap>
								{hour || hour === 0
									? <ProgressHour
										style={{
											width: (Math.floor(hour) / 40) * 100 + '%'
										}}
									></ProgressHour>
									: <ProgressLoader />}

							</ProgressHourWrap>
						</EstimatedHoursWrap>

						{
							+this.props.auth.user.id === +userDetail.id ? (
								<LeaveStatsView user={this.props.auth.user.userDetail} />
							) : ('')
						}

						<div className="accordion accordion__panel accordion__panel--profile">
							<Tabs
								id="controlled-tab-example"
								activeKey={tabKey}
								onSelect={this.estimatedTab}
							>
								<Tab
									eventKey={'1'}
									title={`${+userDetail.id === +this.props.auth.user.id ? 'Your Estimations' : `Weekly estimations for ${userDetail.firstName}`}`}
								>
									{/* <h2>{`${+userDetail.id === +this.props.auth.user.id ? 'Your' : `${userDetail.firstName}'s`}`} weekly estimations</h2>
									{projectList && projectList.length ? (
										<div>
											<table>
												<ProjectDetailHeader
													data={weekList}
													selectedWeek={selectedWeek}
												/>
												{projectList.map(p => (
													<ProjectDetailBody
														key={p.id}
														data={p}
														userId={userDetail.id}
													/>
												))}
											</table>
										</div>
									) : (
										<span>
											{projectList
												? 'No Projects added from last two weeks.'
												: 'Loading...'}
										</span>
									)} */}
									<Project
										val={this.state.selectedWeek}
										sendData={this.getWeekDetail}
										addEstimate={this.handleMenuClick}
										addDeliverable={this.handleDeliverableClick}
										userId={userId}
										profile={true}
									/>
								</Tab>
								{isSupervisor && this.props.auth.user.id === userDetail.id ? (
									<Tab eventKey={'2'} title="Team's estimations">
										<Team />
									</Tab>
								) : (
										''
									)}
								{this.props.auth.user.id === userDetail.id ? (
									<Tab eventKey={'3'} title='Leave and Other Details'>
										<Stats />
									</Tab>
								) :
									('')
								}

							</Tabs>
						</div>

					</InnerTemplate>
				)}
			</ProfileWrapper>
		);
	}
}

const ProjectDetailHeader = props => {
	if (props.data) {
		const header = props.data.map((h, i) => {
			if (h.isCurrentWeek) {
				return (
					<th key={i} className="active">
						<table>
							<tbody>
								<tr>
									<th className="selected-week-table-head">This week</th>
								</tr>
								<tr>
									<th>{h.weekNo}</th>
								</tr>
							</tbody>
						</table>
						{/* <span>This week</span>{h.weekNo} */}
					</th>
				);
			} else {
				return <th key={i}>{h.weekNo}</th>;
			}
		});

		return (
			<thead>
				<tr>
					<th className="week-label">Week # →</th>
					{header}
				</tr>
			</thead>
		);
	}
	return (
		<thead>
			<tr></tr>
		</thead>
	);
};

const ProjectDetailBody = props => {
	if (props.data) {
		const data = props.data.projectDetail.arrangeByUser.find(
			(row, index) => +row.userId === +props.userId
		);

		return (
			<tbody key={props.userId}>
				<tr>
					<td>{props.data.title}</td>
					{data.detail.map((r, i) => {
						return props.data.projectDetail.weeks.map((w, i) => {
							if (w.id === r.week.id) {
								if (r.week.isCurrentWeek) {
									return (
										<td key={i} className="active">
											{r.hour}
										</td>
									);
								} else {
									return <td key={i}>{r.hour}</td>;
								}
							}
							return null;
						});
					})}
				</tr>
			</tbody>
		);

		// return <tbody>{rows}</tbody>;
	}
	return null;
};

const mapStateToProps = state => ({
	auth: state.auth,
	user: state.user.userDetail,
	weekData: state.week.weekData,
	userProjects: state.project.projectList
});

const mapDispatchToProps = dispatch => ({
	fetchUserProfile: (userId = '') => {
		dispatch(UserAction.fetching);
		UserService.fetchUserProfile(userId)
			.then(response => {
				if (response.success) {
					dispatch(UserAction.fetched(response));
				}
				dispatch(UserAction.error(response));
			})
			.catch(err => dispatch(UserAction.error(err)));
	},
	resetUserProfile: () => {
		dispatch(UserAction.fetched({ data: {} }));
	},
	getUserAttendance: (weekId) => {
		dispatch(UserAction.attendanceFetching);
		UserService.fetchUserAttendance(weekId)
			.then((response) => {
				if (response.success) {
					dispatch(UserAction.attendanceFetched(response));
				} else {
					dispatch(UserAction.attendanceError(response));
				}
			})
			.catch((err) => dispatch(UserAction.attendanceError(err)));
	},
	listUserProjects: (weekId, userId) => {
		dispatch(ProjectAction.userProjectFetching);
		ProjectApi.listUserCurrentProject({ weekId, userId })
			.then((response) => {
				if (response.success) {
					dispatch(ProjectAction.userProjectFetched(response));
				} else {
					dispatch(ProjectAction.userProjectError(response));
				}
			})
			.catch((err) => {
				dispatch(ProjectAction.userProjectError(err));
			});
	},
	getWeekData: (year) => {
		dispatch(WeekAction.fetching);
		WeekApi.listWeeksByYear({ year })
			.then((response) => {
				if (response.success) dispatch(WeekAction.fetched(response));
				dispatch(WeekAction.error("error fetching week"));
			})
			.catch((err) => {
				dispatch(WeekAction.error(err.message));
			});
	},
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Index);

const ProfileWrapper = styled.div`
	padding-bottom: 60px;
	position: relative;
`;

const InnerTemplate = styled.div`
	margin: 0 auto;
	padding: 150px 0 40px;
	position: relative;
	width: 924px;
	.accordion__button {
		// div {
		// 	border-bottom: 1px solid rgba(40, 45, 58, 0.1);
		// 	margin-bottom: 5px;
		// 	margin: 0 -10px 0 -22px;
		// 	padding: 16px 16px 16px 52px;
		// }
	}
	.accordion__panel--profile {
		background: transparent;
	}
	#controlled-tab-example {
		>.nav-tabs {
			border-bottom: 2px solid rgba(255,255,255,0.1);
			margin-bottom: 40px;
			padding: 0;
			> li {
				a,
				a:hover,
				a:focus {
					border: 0;
					outline: 0;
				}
				a {
					color: rgba(255, 255, 255, 0.5);
					font-size: 11px;
					text-transform: uppercase;
					padding: 4px 12px;
					&:hover,
					&:focus {
						background: transparent;
					}
				}
				&.active {
					a,
					a:hover,
					a:focus {
						background: transparent;
						border-bottom: 1px solid #f26722;
						color: #fff;
						font-weight: bold;
					}
				}
			}
		}
		div[id="controlled-tab-example-pane-1"] {
			overflow: hidden;
			padding-bottom: 0px;
		}

		div[id*="controlled-tab-example-pane-2"] {
			background: #fff;
			border-radius: 4px;
			padding-bottom: 20px;
			h2 {
				border-bottom: 1px solid #ddd;
				font-size: 16px;
				font-weight: 700;
				padding: 8px 28px 28px;
				& ~ span {
					display: block;
					padding: 10px 28px;
				}
			}
		}

		#controlled-tab-example-pane-3 {
			background: transparent;
		}

		.select-leave {
			width: 150px;
			display: inline-block;
			border-radius: 2px;
			border: solid 1px #51804d;
			font-size: 12px;
			height: 34px;
			margin-right: 20px;
			div {
				background: transparent;
				border: none;
				min-height: initial;
			}
			.react-select {
				&__placeholder {
					color: #fff;
					font-size: 12px;
					font-weight: 300;
				}
				&__single-value {
					color: #ffffff;
					text-transform: uppercase;
				}
				&__indicator-separator {
					display: none;
				}
				&__menu {
					background: rgba(0, 0, 0, 0.9);
				}
				&__menu-list {
					border: 1px solid #51804d;
					border-radius: 4px;
					background: rgba(0, 0, 0, 0.9);
				}
				&__option {
					color: #ffffff;
					text-transform: uppercase;
					font-size: 12px;
					font-weight: 300;
				}
			}
		}
	}

	#controlled-tab-example-pane-2 table {
		margin-top: -46px;
		th:not(.week-label) {
			color: #333;
			font-size: 16px;
			padding: 12px;
		}
	}

	/* #controlled-tab-example-tab-2:after {
		background-image: linear-gradient(244deg, #ff6200, #db2b2b);
		border-radius: 4px;
		color: #fff;
		content: 'This Week';
		display: inline-block;
		font-size: 8px;
		font-weight: bold;
		line-height: 24px;
		margin-left: 4px;
		text-align: center;
		text-transform: uppercase;
		vertical-align: middle;
		width: 56px;
	} */
`;

const ProfileHeader = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-bottom: 40px;
	.profile-image {
		width: 106px;
		height: 106px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 24px;
		img {
			object-fit: cover;
			object-position: top;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;
		}
	}
	.profile-info {
		.profile-attendance {
			display: inline-flex;
			align-items: baseline;
			h3 {
				font-size: 36px;
				font-weight: 300;
				margin-right: 20px;
			}
			strong {
				font-size: 12px;
				font-weight: 700;

				position: relative;
				&::before {
					content: '';
					height: 8px;
					width: 8px;
					border-radius: 50%;
					position: absolute;
					bottom: 4px;
					left: -12px;
				}
				&.present {
					&::before {
						content: '';
						background: #6da360;
					}
				}
				&.absent {
					&::before {
						content: '';
						background: red;
					}
				}
				.leave-icon{
					margin-left:4px;
				}
				small {
					opacity: 0.7;
					font-size: 12px;
					font-weight: 500;
					font-style: italic;
					margin-left: 5px;
					img {
						margin: 0 -3px 0 2px;
						transform: scale(0.8);
						vertical-align: sub;
					}
				}
			}
		}
		.other-info {
			span,
			a {
				display: inline-block;
				font-size: 12px;
				font-weight: 500;
				line-height: 12px;
				margin-right: 6px;
				opacity: 0.5;
			}
			a {
				color: #fff;
				font-size: 10px;
				font-weight: 500;
				margin-left: 10px;
				text-transform: uppercase;
				text-decoration: underline;
				&:hover {
					opacity: 1;
				}
			}
			span + span {
				border-left: 1px solid rgba(255, 255, 255, 0.5);
				padding-left: 6px;
			}
			span:empty {
				display: none;
			}
		}
	}
`;
const EstimatedHoursWrap = styled.div`
	margin-bottom: 46px;
	> div {
		align-items: center;
		color: rgba(255, 255, 255, 0.5);
		display: flex;
		font-size: 14px;
		justify-content: space-between;
		position: relative;
		h4 {
			font-size: 14px;
			margin: 0;
			text-transform: uppercase;
		}
	}
`;

const EstimatedHour = styled.span`
	font-size: 14px;
	strong {
		color: #fff;
		font-size: 30px;
	}
`;

const ProgressHourWrap = styled.div`
	position: relative;
	background: rgba(242, 103, 34, 0.2);
	border-radius: 5px;
	height: 3px;
	width: 100%;
	overflow: hidden;
`;

const ProgressLoader = styled.div`
  position: absolute;
  width: 27px;
  height: 3px;
  background-color: #F26722;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loading;
  animation-timing-function: linear;
  
  @keyframes loading {
    0% {
      left: 0;
      transform: translateX(-100%);
    }

    20% {
      left: 0;
      transform: translateX(-100%);
    }

    80% {
      left: 100%;
      transform: translateX(0%);
    }

    100% {
      left: 100%;
      transform: translateX(0%);
    }
  }
`;

const ProgressHour = styled.div`
	background: #f26722;
	border-radius: 5px;
	height: 3px;
`;
